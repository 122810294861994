

module.exports = {



    /**
     * 去除字符串里的空白
     * 
     * @param {*} value 
     * @param {*} skip 
     * @returns 
     */
    trim: function(value, skip) {
        if (typeof value == 'string'){
            return value.replace(/\s/g,'');
            
        }
        else if (typeof value =='object'){
            for (let key in value){
                if (!skip || skip.indexof(key) == -1){
                    continue;
                }
                value[key] = this.trim(value[key], skip);
        
            }
        }
        return value;
        
    },

    /**
     * 
     * 判断是否以reg开头
     * @param {*} value 
     * @param {*} reg 
     * @returns 
     */
    startsWith: function(value, reg) {
        if (!value || !reg){
            return false;
        }
        if (value.indexOf(reg) == 0){
            return true;
        }
    
        return false;
    },

    decimalFixed(num,rate) {  
        var radio = Math.pow(10, rate);
        var result = parseFloat(num);  
        if (isNaN(result)) {  
            return result;  
        }  
        result = Math.round(num * radio) / radio;  
        return result;  
    }
}