

/**
 * 
 *  操作用户的基本信息工具类
 * 
 */

const key_token = "ACCESS_TOKEN";
const key_access = "ACCESS_ID";
const key_authorities = "ACCESS_AUTHORITY";


module.exports = {

    setAccess: (t, a) => {
        localStorage.setItem(key_token, t);
        localStorage.setItem(key_access, a);
    },

    setAuthorities: (auth) => {
        localStorage.setItem(key_authorities, JSON.stringify(auth));
    },

    getAuthorities: () => {
        let authorities = localStorage.getItem(key_authorities);
        if (authorities && "undefined" != authorities) {
            return JSON.parse(authorities);
        }
        return null;
    },
    isLogin: () => {
        return localStorage.getItem(key_token) ? true : false;
    },
    getAccess: () => {
        return localStorage.getItem(key_access);
    },
    getToken: () => {
        return localStorage.getItem(key_token);
    },
    removeToken: () => {
        localStorage.setItem(key_token, "");
        
    },

    logout: function () {
        localStorage.setItem(key_token, "");
        localStorage.setItem(key_authorities, "");
        localStorage.setItem(key_access, "");
    }

}