<template>
    
    <div>
        <ul class="el-upload-list el-upload-list--picture-card" v-if="url">
            <li  :class="['el-upload-list__item', 'is-' + loading ? 'loading' : 'success', focusing ? 'focusing' : '']"
                
                tabindex="0"
                @keydown.delete="!disabled && deleteUrl"
                @focus="focusing = true"
                @blur="focusing = false"
                @click="chooseFile">
                <img
                    :src="url"
                    alt=""
                    class="el-upload-list__item-thumbnail"/>
                <a class="el-upload-list__item-name" >
                    <i class="el-icon-document"></i> 
                </a>
                <label class="el-upload-list__item-status-label">
                    <i class="el-icon-upload-success el-icon-check"></i>
                </label>
                <i class="el-icon-close"></i>
                <el-progress v-if="loading" type="circle" :percentage="percentage" status="success"></el-progress>
                <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-delete" @click.prevent="deleteUrl"><i class="el-icon-delete"></i></span>
                </span>
            </li>
        </ul>
        <div tabindex="0" class="el-upload el-upload--picture-card" @click="chooseFile" v-show="!url">
            <i data-v-6114a9c4="" class="el-icon-plus"></i>
        </div>
        <input ref="input" type="file" name="file" class="el-upload__input" @change="selectFile">
    </div>
    <!--  -->
</template>

<script>
import Ajax from '@/request/Ajax'

export default {
    name: "app-image-upload",
    props: {
        value: {
            type: String,
            default: "",
        },
        size: {
            type: Number,
            default: 2,
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            action: "/api/smart/upload/standard",
            percentage: 0,
            loading: false,
            focusing: false
        };
    },
    computed: {
        url:{
            get: function(){
                return this.value;
            },
            set: function() {
                this.setUrl();
            }
        }
    },
    watch: {
        value: {
            handler() {
                this.url = this.value;
            },
            deep: true,
            immediate: true,
            wh: 60,
        },
        url() {
            this.setUrl();
        }
    },
    methods: {
        selectFile(event) {
            const files = event.target.files;
            if (!files || !files.length) return;
            const file = files[0];
            const isImage =
                file.type === "image/jpeg" || file.type == "image/png";
            const isLt2M = file.size / 1024 / 1024 < this.size;

            if (!isImage) {
                this.$notify({
                    title: this.$t("common.fail"),
                    message: this.$t("common.image_jpg"),
                });
                return;
            }
            if (!isLt2M) {
                this.$notify({
                    title: this.$t("common.fail"),
                    message: this.$t("common.file_size" + this.size + "M"),
                });
                return;
            }
            this.url = URL.createObjectURL(file);
            this.uploadFile(file);
            return true;
        },
        setUrl() {
            if (this.url != this.value) {
                this.url = this.value;
            }
        },
        chooseFile() {
            if (this.disabled) return;
            this.$refs['input'].value = null;
            this.$refs['input'].click();
        },
        uploadFile(file) {
            const self = this;
            self.loading = true;
            const option = {
                filename: 'file',
                file: file,
                action: this.action,
                onProgress: function(proEvn) {
                    self.percentage = proEvn.percent;
                    self.loading = false;
                },
                onSuccess: function(response) {
                    self.percentage = 100;
                    self.loading = false;
                    console.log(response.body);
                    self.$emit('input', response.body);
                    self.$nextTick(self.setUrl);
                },
                onError: function() {
                    self.loading = false;
                }
            }
            Ajax(option);
        },
        deleteUrl() {
            if (this.disabled) return;
            this.$emit('input', '');
            this.$nextTick(this.setUrl);
        }
    },
};
</script>

<style scoped>
.app-image-upload .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.app-image-upload .el-upload:hover {
    border-color: #409eff;
}
.app-image-upload-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.app-image-upload-hide .el-upload {
    display: none;
}
</style>
