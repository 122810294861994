import Config from '@/config/Config'
import Storage from '@/storage/Storage'


const Command = {
    subscribe: "subscribe",
    desubscribe: "desubscribe",
}

const Topic = {
    admin: "admin",
}


const Channel = {
    CHANNEL_WITHDRAW_AUDIT: 'CHANNEL_WITHDRAW_AUDIT',
    CHANNEL_WITHDRAW_CREATE: 'CHANNEL_WITHDRAW_CREATE',
    CHANNEL_CHARGE_AUDIT: 'CHANNEL_CHARGE_AUDIT',
    CHANNEL_CHARGE_CREATE: 'CHANNEL_CHARGE_CREATE',
    CHANNEL_MARKET_DETAIL: 'CHANNEL_MARKET_DETAIL',
}


function Socket(opt) {

    this.instance = {};
    this.connected = false;
    this.timeout = 0;
    this.context = null;

    this.init = function(opt) {
        console.log("Init socket with:");
        // console.log(opt);
        // 设置vue
        this.context = opt.vue;
    }

    this._reconnect = function() {
        const self = this;
        if (this.timeout < 60000 ) this.timeout += 10000;
        self.connected = false;
        setTimeout(()=>{
            self._connect();
        },self.timeout);
    }

    this._connect = function() {
        console.log(Config.socket);
        const self = this;
		let wsProtocol = 'wss://';
		if (window.location.protocol == 'http:') {
			wsProtocol = 'ws://';
		}
        self.ws = new WebSocket(wsProtocol + window.location.host + Config.socket);
        // 连接成功时
        self.ws.onopen = function() {
            console.log("open");
            // 测试发送消息
            if (self.ws.readyState) {
                self.connected = true;
                self.timeout = 0;
                self._subscribe();
            }
        }
        // 连接断开时
        self.ws.onclose = function(e) {
            console.log('socket onclose');
            self._reconnect();
        }
        // 连接超时
        self.ws.onerror = function(e) {
            console.log('socket onerror');
            self._reconnect();
        }

        // 接收到消息
        self.ws.onmessage = function (e)  {
            const payload = JSON.parse(e.data);
            if (payload.channel == Channel.CHANNEL_CHARGE_CREATE) {
                self.context.$EventBus.$emit('socket.charge.create', payload.content);
            } else if (payload.channel == Channel.CHANNEL_WITHDRAW_CREATE) {
                self.context.$EventBus.$emit('socket.withdraw.create', payload.content);
            } else if (payload.channel == Channel.CHANNEL_CHARGE_AUDIT) {
                self.context.$EventBus.$emit('socket.charge.audit', payload.content);
            } else if (payload.channel == Channel.CHANNEL_WITHDRAW_AUDIT) {
                self.context.$EventBus.$emit('socket.withdraw.audit', payload.content);
            } else if (payload.channel == Channel.CHANNEL_MARKET_DETAIL) {
                self.context.$EventBus.$emit('socket.market.detail', payload.content);
            } 
        }
    }

    // 此方法会直接将message写给服务端，请不要直接使用
    this._send = function(message) {
        const self = this;
        if (!self.connected) return;

        if (typeof message == 'function' || typeof message == 'object' || typeof message == 'array') {
            self.ws.send(JSON.stringify(message));
        } else {
            self.ws.send(message);
        }
    }


    this._subscribe = function() {
        this. _send({
            command: Command.subscribe,
            content: {
                topic: Topic.admin
            }
        })
    }

    

    this.init(opt);
    this._connect();
}

export default Socket;