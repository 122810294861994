<template>
    <!-- 编辑弹出框 -->
    <el-dialog
        :title="title"
        :visible.sync="visible"
        direction="rtl"
        :width="width"
        :before-close="onClose"
        center>
        <div style="padding-right: 30px;">
            <el-form
                :model="value"
                :rules="rules"
                size="mini"
                :ref="name"
                label-width="150px"
                label-position="right"
                @submit.native.prevent>
                <slot name="default"></slot>
            </el-form>
        </div>
        <span slot="footer">
            <el-button
                type="primary"
                :loading="loading"
                @click="submitForm()">
                {{ $t('common.commit') }}
            </el-button>
            <el-button
                type="default"
                @click="resetForm()"
                :loading="loading">
                {{ $t('common.reset') }}
            </el-button>
        </span>
    </el-dialog>
    <!-- 编辑弹出框 -->
</template>


<script>
export default {
    name: 'app-edit-dialog',
    data(){
        return {
            visible: false,
            name: 'editForm',
            loading: false,
            initModel: {},
        }
    },
    props: {
        value: {
            type: Object,
            default: ()=>{}
        },
        // 页面标题
        title: {
            type: String,
            default: '',
        },
        
        rules: {
            type: Object,
            default: ()=>{},
        },
        beforeClose: {
            type: Function,
            default: ()=> true,
        },
        url: {
            type: String,
            default: '',
        },
        beforeCommit: {
            type: Function,
            default: (obj)=> obj,
        },
        afterCommit: {
            type: Function,
            default: function(){},
        },
        width: {
            type: String,
            default: '50%'
        }
        
    },
    methods: {
        
        open: function(){
            this.visible = true;
            this.loading = false;
            for(var key in this.initModel) {
               this.initModel[key] = '';
            }
            this.initModel = Object.assign(this.initModel, this.value);
            Object.assign(this.initModel);
        },
        close: function(){
            this.visible = false;
        },
        onClose: function() {
            if (this.beforeClose()) {
                this.visible = false;
            }
        },
        submitForm: function(){
            if (this.loading) return;
            this.$refs['editForm'].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    var model = this.beforeCommit(this.value);
                    delete model.createdTime;
                    delete model.updatedTime;
                    this.Request.post(this.url, model).then((data)=>{
                        this.loading = false;
                        this.afterCommit(data);
                    }).catch(()=>{
                        this.loading = false;
                    });
                } 
            });
        },
        resetForm: function() {
            var obj = {};
            for(var key in this.initModel) {
                obj[key] = this.initModel[key];
            }
            this.$emit("input", obj);
        }
    },
    mounted: function(){
    }
}
</script>

<style scoped>

</style>