// import crypto from 'crypto'
import md5 from 'js-md5'
import JSEncrypt from 'jsencrypt';


const Encrypt = {

    md5(value){
        return md5(value);
    },
    // sha1(value) {
    //     let crypt = crypto.createHash("sha1");
    //     return crypt.update(value).digest(value).update();
    // },
    
    // sha256: (value) => {
    //     let crypto = crypto.createHash("sha256", 'secret-key');
    //     return crypto.update(value).digest("hex").update();
    // },
    // /**
    //  * AES加密
    //  */
    // aes192Encode: (value) => {
    //     let Aes192Crypt = crypto.createCipher("aes192", "secret-key");
    //     let crypted = Aes192Crypt.update(value, "utf8", "hex");
    //     crypted += Aes192Crypt.final("hex");
    //     return crypted;
    // },
    // /**
    //  * AES解密 
    //  */
    // aes192Decode: (value) => {
    //     let Aes192Crypt = crypto.createCipher("aes192", "secret-key");
    //     let decrypted = Aes192Crypt.update(value, "utf8", "hex");
    //     decrypted += Aes192Crypt.final("hex");
    //     return decrypted;
    // },

    rsaEncode(value, publicKey) {
        if (!value) return "";
        let encryptClient = new JSEncrypt();
        encryptClient.setPublicKey(publicKey);
        return encryptClient.encrypt(value);
    },
    rsaDecode(value, privateKey) {
        if (!value) return "";
        let encryptClient = new JSEncrypt();
        encryptClient.setPrivateKey(privateKey);
        return encryptClient.decrypt(value);
    }

}

export default Encrypt